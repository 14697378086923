import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {NavigationService} from '../../services/navigation.service';
import {StorageService} from "../../services/storage.service";
import {RestService} from "../../services/rest.service";
import {UserToken} from "../../models/userToken";

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {

  key: string;

  constructor(private route: ActivatedRoute, private http: HttpClient, private auth: AuthService, private nav: NavigationService, private storage: StorageService, private rest: RestService) { }

  ngOnInit() {
    this.key = this.route.snapshot.queryParamMap.get("otpKey");
    this.http.get('https://cvm.yuuk.app/app/redirect/otp/'+this.key+'/')
      .subscribe(
        (res: any) => {

          this.http.get('https://cvm.yuuk.app/api/token/authenticate/', {headers: {Authorization: 'Token '+res.auth_token}})
            .subscribe(
              async (res2: UserToken) => {
                console.log(res2)
                this.storage.activeToken = res2;
                this.storage.set("activeToken", res2);
                setTimeout(async () => {
                  this.storage.set("activeToken", res2);
                  await this.auth.checkLogin(true);
                  /**
                  setTimeout(() => {
                    window.location.reload();
                  },500);
                   **/
                  this.nav.navigate("/")
                }, 1500);

              },
              (err2) => {
                console.log(err2)
              }
            )


        },
        (err) => {
          console.log(err);
        }
      )
  }

}
