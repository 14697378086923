import { Component, OnInit } from '@angular/core';
import { format, parseISO } from 'date-fns';
import {RestService} from "../../../services/rest.service";

interface VolunteerNeedForm {
  job_for: string;
  requirements: string;
  needed: string;
  number_of_vols: number;
  number_of_hours: number;
  job_contact: string;
  event: string;
}


@Component({
  selector: 'app-volunteer-need',
  templateUrl: './volunteer-need.component.html',
  styleUrls: ['./volunteer-need.component.scss'],
})
export class VolunteerNeedComponent implements OnInit {

  isSubmitted: boolean = false;
  isSubmitting: boolean = false;

  public volunteerForm: VolunteerNeedForm = {
    job_for: "",
    requirements: "",
    needed: "",
    number_of_vols: 1,
    job_contact: "",
    number_of_hours: 1,
    event: "1"
  };

  constructor(
    public rest: RestService
  ) { }

  ngOnInit() {}


  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }

  submitForm() {
    this.isSubmitting = true;
    console.log(this.volunteerForm);
      this.rest.post("api/volunteer-need/", this.volunteerForm)
        .subscribe(
          (res) => {
            console.log(res);
            this.isSubmitting = false;
            this.isSubmitted = true;
            this.volunteerForm = {
              job_for: "",
              requirements: "",
              needed: "",
              number_of_vols: 1,
              job_contact: "",
              number_of_hours: 1,
              event: "1"
            };
          },
          (err) => {
            console.log(err);
          }
        )
  }
}
