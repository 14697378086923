import { Injectable } from '@angular/core';
import {User} from '../models/user';
import {RestService} from "./rest.service";
import {StorageService} from "./storage.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private rest: RestService,
    private storage: StorageService,
    private router: Router
  ) {

    setTimeout(async () => {
      await this.checkLogin();
    }, 1500);

  }
  public doLogin(username: string, password: string){
    this.rest.post('api/token/', {username: username, password: password})
      .subscribe(
        (res) => {
          console.log(res);
          this.storage.activeToken = res;
          this.storage.set("activeToken", res);
          this.rest.get('app/whoami/').subscribe(
            (res2) => {
              this.storage.activeUser = res2;
              this.storage.set("activeUser", this.storage.activeUser);
            },
            (err2) => {
              console.log(err2);
            }
          )
        },
        (err) => {
          console.log(err);
        }
      )
  }

  async checkLogin(staffLogin: boolean = false) {
    this.storage.activeToken = await this.storage.get("activeToken");

    if(this.storage.activeToken === null){
      return false;
    }else{

    }

    this.rest.post("api/token/refresh/", {refresh: this.storage.activeToken.refresh}).subscribe(
      async (res) => {
        this.storage.activeToken.access = res.access;
        this.storage.activeUser = await this.storage.get("activeUser");
        if(this.storage.activeUser === null){
          // need new user creds
          this.rest.get('app/whoami/', staffLogin).subscribe(
            (res2) => {
              console.log('activityUser', res2);
              this.storage.activeUser = res2;
              this.storage.set("activeUser", this.storage.activeUser);
            },
            (err2) => {
              console.log(err2);
            }
          )
        }
      },
      (err) => {
        this.storage.set("activeUser", undefined);
        this.router.navigate(["/auth"]);
      }
    )

  }

  async doLogout() {
    this.storage.activeUser = undefined;
    this.storage.activeToken = undefined;
    this.storage.set("activeUser", undefined);
    this.storage.set("activeToken", undefined);
  }

  public loadLoginFromStorage(){

  }
}
