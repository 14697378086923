import {Component, Input, OnInit} from '@angular/core';
import {Volunteer} from "../../../models/volunteer";
import {RestService} from "../../../services/rest.service";
import {Punch} from "../../../models/punch";
import {Job} from "../../../models/job";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-show-job-assignment-modal',
  templateUrl: './show-job-assignment-modal.component.html',
  styleUrls: ['./show-job-assignment-modal.component.scss'],
})
export class ShowJobAssignmentModalComponent implements OnInit {

  @Input() id: string;

  punch: Punch;

  jobs: Job[] = [];
  jobString: string = '';

  constructor(
    private rest: RestService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.rest.get('api/punches/'+this.id+'/')
      .subscribe(
        (res: Punch) => {
          this.punch = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  loadSearchJob() {
    this.rest.get('api/jobs/?event=FM2022&completed=false&search='+this.jobString)
      .subscribe(
        (res: Job[]) => {
          this.jobs = res.slice(0,5);
        }
      )
  }

  patchJob(job: Job) {
    this.rest.patch('api/punches/'+this.punch.id+'/', {job: job.id})
      .subscribe(
        (res) => {
          console.log(res);
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
        }
      )
  }
}
