import {Component, HostListener, OnInit} from '@angular/core';
import {ColumnMode} from "@swimlane/ngx-datatable";
import {RestService} from "../../services/rest.service";
import {ToastController} from "@ionic/angular";
import {RfidTag} from "../../models/rfid-tag";
import {Punch} from "../../models/punch";
import * as moment from "moment";
import {Volunteer} from "../../models/volunteer";

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss'],
})
export class KioskComponent implements OnInit {
  isLoaderRunning: boolean = false;
  loaderText: string = "Ready to Scan";
  loadingDataIndicator: boolean = false;
  ColumnMode = ColumnMode;
  reorderableBool: boolean = false;
  activeJobs: any = [];
  activeJobsColumns: any = [
    {prop: 'job_for', name: 'Department'},
    {prop: 'requirements', name: 'Summary'},
    {prop: 'needed', name: 'Needed At'},
    {prop: 'number_of_vols', name: '#'},
    {prop: 'number_of_hours', name: 'Hrs'},
    {prop: 'multiplier', name: 'x'},

  ];
  activeVolunteers: any = [];
  activeVolunteersColumns: any = [
    {prop: 'call_name', name: 'Name'},
    {prop: 'lastJob', name: 'Assignment'},
    {prop: 'isAssignedSince', name: 'Since'}
  ];
  barcodeScan: string = '';

  constructor(
    private rest: RestService,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    this.doActiveJobRefresh();
    this.doActiveVolunteerRefresh();
  }

  doActiveVolunteerRefresh(){
    this.rest.get('api/volunteers/?event=FM2022')
      .subscribe(
        (res) => {
          this.activeVolunteers = [];
          for (let i = 0; i < res.length; i++) {
            if(res[i].isAssigned){
              this.activeVolunteers.push(res[i])
            }
          }
        }
      )
  }

  doActiveJobRefresh(){
    this.rest.get('api/jobs/?event=FM2022&completed=false&on_kiosk=true')
      .subscribe(
        (res) => {
          this.activeJobs = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  @HostListener('window:keypress', ['$event'])
  onKeydown(event){
    //console.log(event);
    if(event.key === 'Enter'){
      //process barcode punch in

      let t = this.barcodeScan
      this.clock(t);
      this.barcodeScan = "";

    }else{
      this.barcodeScan += event.key
    }
    //window.focus()
  }

  private clock(t: string) {
    this.isLoaderRunning = true;
    this.rest.get('api/rfid-tags/?serial_number='+t)
      .subscribe(
        async (tags: RfidTag[]) => {

          if (tags.length === 0) {
            this.isLoaderRunning = false;
            const toast = await this.toastController.create({
              message: 'This Tag is not registered.',
              duration: 2000,
              position: 'middle',
            });
            toast.present();
            this.isLoaderRunning = false;
            return false;
          }

          let tag: RfidTag = tags[0];

          if (tag.volunteerIsClockedIn) {
            this.rest.get('api/volunteers/' + tag.volunteer.id + '/')
              .subscribe(
                (v: Volunteer) => {
                  this.rest.patch('api/punches/' + v.getCurrentJobId + '/', {
                    time_out_user: '3',
                    time_out_user_id: 3,
                    time_out: moment().format("YYYY-MM-DD HH:mm:ss")
                  })
                    .subscribe(
                      async (res) => {
                        const toast = await this.toastController.create({
                          message: 'Clocked Out Successfully.',
                          duration: 2000,
                          position: 'middle',
                        });
                        toast.present();
                        this.isLoaderRunning = false;
                        this.doActiveVolunteerRefresh();
                      }
                    )
                },
                (err) => {
                  this.isLoaderRunning = false;
                }
              )
          } else {
            console.log(tag);
            let newPunch: Punch = {
              volunteer: tag.volunteer.id,
              rf_tag: tag.id,
              time_in_user: '3',
              time_in_user_id: 3,
              time_in: moment().format("YYYY-MM-DD HH:mm:ss")
            } as Punch
            this.rest.post('api/punches/', newPunch)
              .subscribe(
                async (res) => {
                  console.log(res)
                  const toast = await this.toastController.create({
                    message: 'Punched In Successfully.',
                    duration: 2000,
                    position: 'middle',
                  });
                  toast.present();
                  this.isLoaderRunning = false;
                  this.doActiveVolunteerRefresh();
                },
                (err) => {
                  console.log(err)
                  this.isLoaderRunning = false;
                }
              )

          }

        },
        async (err) => {
          const toast = await this.toastController.create({
            message: 'This Tag is not registered.',
            duration: 2000,
            position: 'middle',
          });
          toast.present();
          this.isLoaderRunning = false;
        }
      )
  }
}
