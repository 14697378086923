import { Injectable } from '@angular/core';
import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {EMPTY, Subject} from "rxjs";
import {catchError, switchAll, tap} from "rxjs/operators";

export const WS_ENDPOINT = "wss://cvm.yuuk.app/ws/actions/cvm/"

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private socket$: WebSocketSubject<any> | undefined;
  private messagesSubject$ = new Subject();
  // @ts-ignore
  public messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));

  public heartbeat: any;

  constructor() {
    this.heartbeat = "";
  }

  public connect(): void {
    if(!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();

      this.socket$.subscribe(
        msg => this.recieveMessage(msg), // Called whenever there is a message from the server.
        err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
        () => console.log('complete') // Called when connection is closed (for whatever reason).
      );

      this.heartbeat = setInterval(
        () => {
          this.sendMessage({"action": "HEARTBEAT", "message": "none"})
        },10000
      )

      const messages = this.socket$.pipe(
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY));
      this.messagesSubject$.next(messages);
      console.log(this.socket$);
    }
  }

  private getNewWebSocket() {
    return webSocket(WS_ENDPOINT);
  }

  recieveMessage(msg: any){
    console.log("Message from server: "+msg.message)
  }

  sendMessage(msg: any){
    if(this.socket$){
      console.log(this.socket$);
      this.socket$.next(msg);
    }

  }

  close() {
    if(this.socket$) {
      this.socket$.complete();
    }
  }

}
