import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {AuthComponent} from './components/auth/auth.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ExternalLoginComponent} from './components/external-login/external-login.component';
import {HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from "@ionic/storage-angular";
import {FormsComponent} from "./components/forms/forms.component";
import {VolunteerNeedComponent} from "./components/forms/volunteer-need/volunteer-need.component";
import {KioskComponent} from "./components/kiosk/kiosk.component";
import {FormsModule} from "@angular/forms";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ManagerComponent} from "./components/manager/manager.component";
import {ShowTsModalComponent} from "./components/manager/show-ts-modal/show-ts-modal.component";
import {TimesheetVerifyComponent} from "./components/timesheet-verify/timesheet-verify.component";
import {
  ShowJobAssignmentModalComponent
} from "./components/manager/show-job-assignment-modal/show-job-assignment-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    DashboardComponent,
    ExternalLoginComponent,
    FormsComponent,
    VolunteerNeedComponent,
    KioskComponent,
    ManagerComponent,
    ShowTsModalComponent,
    TimesheetVerifyComponent,
    ShowJobAssignmentModalComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    NgxDatatableModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
