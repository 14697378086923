import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class RestService {


  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  get(uri: string, staffLogin: boolean = false): Observable<any>{
    let headers: any = {};
    if(this.storage.activeToken !== undefined && this.storage.activeToken !== null){
      headers = {Authorization: "Bearer "+this.storage.activeToken.access}
    }
    if(staffLogin){
      headers = {Authorization: "Bearer "+this.storage.activeToken.access};
    }
    return this.http.get(environment.baseUri+uri, {headers: headers})
  }

  post(uri: string, data: any): Observable<any>{
    let headers: any = {};
    if(this.storage.activeToken !== undefined && this.storage.activeToken !== null){
      headers = {Authorization: "Bearer "+this.storage.activeToken.access}
    }
    return this.http.post(environment.baseUri+uri, data, {headers: headers})
  }
  patch(uri: string, data: any): Observable<any>{
    let headers: any = {};
    if(this.storage.activeToken !== undefined && this.storage.activeToken !== null){
      headers = {Authorization: "Bearer "+this.storage.activeToken.access}
    }
    return this.http.patch(environment.baseUri+uri, data, {headers: headers})
  }
}
