import { Component, OnInit } from '@angular/core';
import {DataService} from "../../services/data.service";
import {catchError, map, tap} from "rxjs/operators";
import {Volunteer} from "../../models/volunteer";
import {ColumnMode} from "@swimlane/ngx-datatable";
import {RestService} from "../../services/rest.service";
import {ModalController} from "@ionic/angular";
import {ShowTsModalComponent} from "./show-ts-modal/show-ts-modal.component";
import {Punch} from "../../models/punch";
import {ShowJobAssignmentModalComponent} from "./show-job-assignment-modal/show-job-assignment-modal.component";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
})
export class ManagerComponent implements OnInit {

  constructor(
    private socket: DataService,
    private rest: RestService,
    private modalController: ModalController
  ) {



  }

  selectedPanel: string = "volunteers";

  liveData$ = this.socket.messages$.pipe(
    map(rows => { // @ts-ignore
      rows.data}),
    catchError(error => {throw error}),
    tap({
      error: error => console.log('[Live component] Error: ', error),
      complete: () => console.log('[Live component] Connection closed')
    })
  )
  loadingDataIndicator: boolean;
  volunteerList: Volunteer[] = [];
  public ColumnMode: ColumnMode;
  reorderableBool: boolean = true;

  punchListNoJob: Punch[] = [];

  ngOnInit(): void {
    this.socket.connect();
    this.loadJobInbox();
  }

  doTestSend() {
    this.socket.sendMessage({'message': 'hi from angular'});
  }

  loadJobInbox(){
    this.rest.get('api/punches/?job__isnull=true')
      .subscribe(
        (res: Punch[]) => {
          this.punchListNoJob = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  loadVolunteers(){
    this.rest.get('api/volunteers/')
      .subscribe(
        (res: Volunteer[]) => {
          console.log(res);
          this.volunteerList = res;
        }
      )
  }

  segmentChanged($event: any) {
    console.log($event);
    this.selectedPanel = $event.detail.value;

    switch (this.selectedPanel){
      case 'volunteers':
        this.loadVolunteers();
        break;
      case 'inbox':
        this.loadJobInbox();
        break;
    }

  }

  async displayTimesheetModel(elem: any) {
    console.log(elem);
    const modal = await this.modalController.create({
      component: ShowTsModalComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        'id': elem,
      }
    });
    return await modal.present();
  }

  async displayAssignJobModal(rowElement: any) {
    const modal = await this.modalController.create({
      component: ShowJobAssignmentModalComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        'id': rowElement,
      }
    });
    await modal.present();

    this.loadJobInbox();

    return true;
  }

  refreshApp() {
    window.location.reload();
  }
}
