import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AuthComponent} from "./components/auth/auth.component";
import {AuthGuard} from "./guards/auth.guard";
import {ExternalLoginComponent} from "./components/external-login/external-login.component";
import {FormsComponent} from "./components/forms/forms.component";
import {VolunteerNeedComponent} from "./components/forms/volunteer-need/volunteer-need.component";
import {KioskComponent} from "./components/kiosk/kiosk.component";
import {TimesheetVerifyComponent} from "./components/timesheet-verify/timesheet-verify.component";
import {ManagerComponent} from "./components/manager/manager.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'timesheetVerify/:id',
    component: TimesheetVerifyComponent
  },
  {
    path: 'kiosk',
    component: KioskComponent
  },
  {
    path: 'manager',
    component: ManagerComponent
  },
  {
    path: 'external/login',
    component: ExternalLoginComponent
  },
  {
    path: 'forms',
    component: FormsComponent
  },
  {
    path: 'forms/volunteer-need',
    component: VolunteerNeedComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
