import {Component, Input, OnInit} from '@angular/core';
import {RestService} from "../../../services/rest.service";
import {Volunteer} from "../../../models/volunteer";

@Component({
  selector: 'app-show-ts-modal',
  templateUrl: './show-ts-modal.component.html',
  styleUrls: ['./show-ts-modal.component.scss'],
})
export class ShowTsModalComponent implements OnInit {

  @Input() id: string;

  volunteer: Volunteer;

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    this.rest.get('api/volunteers/'+this.id+'/')
      .subscribe(
        (res: Volunteer) => {
          this.volunteer = res;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  printTimesheet() {
    this.rest.get('app/test/'+this.volunteer.id+'/')
      .subscribe(
        (res) => {
          alert("Sent to Celery")
        },
        (err) => {
          alert("An error occured")
          console.log(err);
        }
      )
  }
}
