import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {StorageService} from "../../services/storage.service";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public storage: StorageService,
    public navigation: NavigationService
  ) { }

  ngOnInit() {
  }

}
