import { Component, OnInit } from '@angular/core';
import {RestService} from "../../services/rest.service";
import {ActivatedRoute} from "@angular/router";
import {TimesheetVerification} from "../../models/timesheet-verification";

@Component({
  selector: 'app-timesheet-verify',
  templateUrl: './timesheet-verify.component.html',
  styleUrls: ['./timesheet-verify.component.scss'],
})
export class TimesheetVerifyComponent implements OnInit {
  loading: boolean = true;
  route: ActivatedRoute;

  timesheet: TimesheetVerification;
  notError: boolean = true;

  constructor(
    public rest: RestService,
    route: ActivatedRoute,
  ) {
    this.route = route;
    this.route.params.subscribe(params => {
      this.reload(params['id']);
    })
  }

  ngOnInit() {}

  reload(id: string){
    this.loading = true;
    this.rest.get('api/timesheets/?check_id='+id)
      .subscribe(
        (res: TimesheetVerification[]) => {
            if(res.length === 0){
              this.loading = false;
              this.notError = false;
            }else{
              this.timesheet = res[0];
              this.loading = false;
              this.notError = true;
            }
        },
        (err) => {
          this.loading = false;
          this.notError = false;
        }
      )
  }

}
